import React from 'react';

function WindSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill='#C3C3C3'
      viewBox='0 0 64 64'
      {...props} >
      <g fillRule='evenodd' clipRule='evenodd'>
        <path d='M44.297 33.77c-.302-.099-.49-.31-.479-.72-1.164.476-5.575.962-4.561 0-1.833.015-3.074-.421-3.6.479-.409-.098.05-.31 0-.72-.854.761-2.258-.231-2.88.96-.071-.488-.48-.64-.72-.96-3.586.354-8.604-.109-11.28.72.18-.453.716-.166.24-.72-1.53.254-2.074-.287-3.36-.96-8.256-4.321-4.754-17.732 4.32-17.04 1.175-.648 1.833-.677 2.64-1.92.345-2.185-.694-2.986-1.92-3.6h-.72c-.66.757-1.255.152-2.4.24-10.28 1.077-15.114 14.026-9.36 22.8 2.699 2.572 5.722 5.317 10.8 5.76 10.021.874 26.979-.25 36.48-.48-.013-.413.185-.615.479-.72.159-1.279-.186-2.055-.72-2.64-4.329-.404-8.471-.886-12.959-.479z' />
        <path d='M52.697 25.129c-6.403.249-14.019.391-21.84.24-1.84-1.306-3.382-2.995-2.4-5.28.951-2.212 4.901-.945 5.52-3.36 1.089-4.246-5.1-2.523-6.72-1.44-5.701 3.812-3.427 13.273 2.88 14.64 2.957.641 5.904.1 8.641.48.162.022.623-.342.479.48 2.325-.461 3.646-.967 4.32.48 1.642-1.279 1.588.401 2.88-.96 2.032 1.063 5.817.999 7.439-.24.432 1.54 4.995 1.053 4.801-1.92h.239v-.96c-1.051-2.224-3.13-2.28-6.239-2.16zM43.577 39.77c-1.006.046-.742-1.178-1.92-.96-2.261 1.06-8.286-.951-12.48.96.085-.315.423-.378.48-.72-3.135.209-5.579-.011-7.92 0-.774.003-1.691.497-2.4.479-.883-.021-2.58-.568-4.32-.239-2.773.523-4.408 2.34-5.76 5.279-.277 4.037 2.217 7.631 6.24 8.16h1.2c1.837-.896 2.475-2.956 1.44-4.32-.963-1.269-2.67-.549-3.6-1.92.039-3.444 3.724-2.127 6.96-2.16 10.848-.11 25.233.026 36-.72.689-.887.618-2.722-.24-3.359-5.025-.365-10.123-1.325-13.68-.48z' />
      </g>
    </svg>
  );
}

export default WindSvg;
