import React from 'react';

function Person(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 64 64" {...props}>
      <g fillRule="evenodd" clipRule="evenodd">
        <path d="M51.353 39.899c-1.153-1.363-4.738-5.179-6.72-5.28-1.506-.076-1.798 1.129-3.36 1.92-.059.03-4.571 1.715-5.28 1.681-.653-.032-2.339-.369-4.08.479-.55-.25-.667-.933-1.2-1.2-.466.19-1.454 1.085-1.92.48.408.088.453-.188.48-.48-.856.935-1.604-.662-2.4.48-.268-.558.051-.643-.48-1.2-2.683-.003-3.814-2.368-7.2-3.12-.781.26-.917 1.163-1.68 1.44.268-.296.209-.493-.24-.48-4.132 3.246-8.85 7.953-9.6 14.16-1.618 1.753-.701 5.498-1.44 6.72v.721c.422.218.439.841.72 1.199 18.183.437 33.275-.174 50.16-.72.038-.282-.041-.682.24-.72v-.24c-.714-5.798-2.405-11.588-6-15.84zM36.232 55.02c-8.376.009-17.782-.04-27.12 0 .158-6.264 3.29-8.617 4.56-12.721.942.566.282.121 1.44 0 .806-.617.033-1.036.48-1.68.261.06.163.478.48.48-.17-.578 1.204-1.033.72-1.681 1.228.133 1.631-.946 2.64-1.199 1.274.255 2.887 1.91 4.56 1.439-.059.261-.477.163-.48.48 7.273 3.209 16.334 1.274 21.121-2.641 5.596 4.139 8.429 8.905 10.08 16.8-5.125.874-11.734.715-18.481.723zM33.593 33.18c1.453-.059 3.782-1.3 5.04-.96-.239-.788 1.61-.733 1.68-1.92.833 1.123.253-.474 1.2.24.925-2.273 5.09-5.284 3.6-8.64 1.226.152.937-2.809.48-4.8-.468-2.037-1.959-4.43-2.88-5.52-.553-.653-.496-.987-.721-1.2-2.593-2.459-5.89-3.447-8.88-4.08h-2.16c-4.342.362-7.53 3.352-10.08 6.24-5.487 10.502 1.676 21.084 12.721 20.64zM21.112 18.059c1.423-5.785 6.493-9.914 13.2-8.88.343.857 1.335 1.065 2.4 1.2.287.315.093.377 0 .72.409-.009 1.339.252 1.92.72-1.221.34-.091-.093 0 1.2.43.03.506-.294.96-.24-.085.315-.423.377-.48.72 1.235-.516-.091.46 1.44.24-.745 1.343.887 3.145 1.92 4.56-.106.349-.211-.024-.48 0 .35 4.361-1.301 8.629-4.08 10.56-.592.412-2.636 1.268-3.359 1.44-4.055.962-8.078-1.369-11.041-3.12-1.303-2.926-2.859-4.905-2.4-9.12z" />
      </g>
    </svg>
  )
}

export default Person;
