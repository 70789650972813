import React from 'react';

function Dot(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 64 64" {...props}>
      <circle cx={32} cy={32} r={23.5} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.866 6.99c-6.407-.459-9.223.535-13.2 2.4-.679.198-.622-.338-1.2-.24-1.094 2.242-2.812 1.31-3.6 3.36-.957.132-.723.132-1.68 0-.732.96-.331 1.577-1.2 2.4-.01-.331.113-.527.24-.72-.768.752-1.843 1.197-1.68 2.88-.753-.267-.634-.112-1.2.24-1.813 3.517-4.142 7.301-3.36 11.04-.453-.18-.167-.716-.72-.24-.263.914.635 1.499-.24 1.68v3.6c.883 9.818 6.36 16.522 13.2 21.12 2.739.491 4.925 2.894 7.44 2.16 8.877 2.545 17.303-.701 22.56-6 4.057-4.09 7.28-9.944 7.92-16.56 1.349-13.945-9.256-26.115-23.28-27.12zm20.88 28.32c-1.485 11.816-13.703 22.573-27.12 19.2-2.01-.505-3.232-1.771-5.76-2.16-.134-.489-.722-.471-.24-.96-1.572.346-2.174-1.638-4.08-1.68.195-.83-.448-1.013 0-1.44-.324-.796-1.559-.681-2.16-1.199.105-.356.307.095.72 0-.9-.46-1.102-1.619-2.4-1.681.134-.489.722-.471.24-.96-2.283-2.312-2.114-6.076-4.56-8.64.209-.698.359-.27.96-.24-1.187-3.84.267-9.374.96-12.48 1.426-1.214 2.195-3.084 2.88-5.04 1.91-1.209 3.295-2.944 4.8-4.56 3.429-1.192 6.288-3.462 10.08-3.84 8.124-.811 16.239 3.019 21.12 8.16 3.167 5.08 5.43 10.596 4.56 17.52z"
      />
    </svg>
  );
}

export default Dot;