import React from 'react';

function Location(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 64 64" {...props}>
      <g fillRule="evenodd" clipRule="evenodd">
        <path d="M25.635 7.71c-1.326.409-2.023 1.713-3.6 1.44-.619 1.397-2.585 2.125-2.88 3.12-.056-.372.018-.614.156-.792-.943.703-2.248 1.835-2.111 2.983-.205-.511-1.284 1.118-.924 1.889-1.515.988-2.247 3.996-1.92 6.96-.006.233-.294.186-.48.24v.72c.289.912.94 1.46 1.2 2.4-.346.128-.586-.935-.72-.24.706 3.517 2.938 8.446 4.32 10.56 1.787 4.581 4.654 7.825 6.48 11.76 1.166 1.494 1.984 2.915 3.36 4.8 1.494 2.047 2.8 6.303 5.76 2.16.598-.837 1.378-1.694 1.68-2.16 2.097-3.237 3.022-6.728 4.8-8.399 1.998-6.407 7.668-12.321 8.4-20.16C50.273 13.017 37.369 4.088 25.635 7.71zm17.52 27.84c-3.141 6.855-8.059 13.329-11.04 18.48-2.285-2.275-3.092-6.028-5.28-8.4-.102-.502.165-.636.24-.96-.723-.221-1.306-1.064-.96-1.68-.298-.099-.698-.273-1.2-.721.417-1.794.06-2.074-1.2-2.399 1.251.087-.108-1.462-.72-1.44-.185-2.084-1.788-5.171-3.84-7.68-.52.58-.013-.805.48 0-1.286-2.74-1.67-5.689-1.44-9.12.048-.713-.193.094.24-.96.397-.966.337-2.586.72-3.36 4.11-8.311 15.773-10.154 23.52-3.6.239 1.281 1.41 1.63 2.16 2.4 3.749 6.881 1.32 12.89-1.68 19.44z" />
        <path d="M19.311 11.478c.214-.16.41-.298.564-.408-.213.111-.423.225-.564.408zM28.515 19.71c.043-.92-.197-.087-.72-.24.043.96-1.041 1.132-1.44 2.16-1.514 3.9 2.345 8.35 6 8.16 3.328-.864 5.685-4.287 4.56-7.68-1.046-3.156-6.26-4.05-8.4-2.4zm2.64 6.24c-1.824-.659-1.53-5.903 3.121-3.84 2.202 2.678-2.195 6.522-3.121 3.84z" />
      </g>
    </svg>
  );
}

export default Location;
